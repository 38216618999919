import crudMutations from "@/store/templates/crud/mutations";

export default {
  setName(state, name) {
    state.model.name = name;
  },
  setType(state, type) {
    state.model.type = type;
  },
  ...crudMutations
};
